

































import { errorNotification } from '@/includes/services/NotificationService'
import { ArticlesApi } from '@/includes/Api/Articles.api'
import { ArticleRequestData, HTMLRequestData, PossibleArticleRequestData } from "@/includes/types/Articles.types";

import PostApi from 'piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import Vue from 'vue'
import Component from 'vue-class-component'
import EditorJS, { OutputData } from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Table from '@editorjs/table'
import SimpleImage from '@editorjs/simple-image'
import Warning from '@editorjs/warning'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import List from '@editorjs/list'
import Link from '@editorjs/link'
import Embed from './custom-editorjs-plugins/embed/index'
import CheckList from '@editorjs/checklist'
import InlineConde from '@editorjs/inline-code'
import UnderLine from '@editorjs/underline'
import Delimiter from '@editorjs/delimiter'
import TextVariantTune from '@editorjs/text-variant-tune'
import NestedList from '@editorjs/nested-list'
import { Guid } from 'guid-typescript'
import { Ref } from 'vue-property-decorator'

const REF_KEY = 'container'

@Component({
  data() {
    return {
      REF_KEY
    }
  },
  components: {
    PageTitle
  }
})
export default class ArticleReadOnly extends Vue {

  @Ref(REF_KEY) container!: HTMLDivElement

  articleTitle: string = ''

  content: PossibleArticleRequestData | null = null

  editor: EditorJS | null = null

  isLoading = false

  checkTelegramLinks(text: string): string {
    let regexTChannel = new RegExp(/(t|telegram).me\/([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g)
    let map = new Map<string, string>()

    text = text.replace(regexTChannel, match => this.checkGuidInText(match, map))

    for (let url of map) {
      let key = url[0]
      let value = url[1]
      let newRegex = new RegExp(value, 'g')
      text = text.replace(newRegex, '<a target="_blank" href="https://' + key + '">' + key + '</a>')
    }

    return text
  }

  replaceTelegramUsers(text: string): string {
    let regexTName = new RegExp(/@([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g)
    let map = new Map<string, string>()

    text = text.replace(regexTName, match => this.checkGuidInText(match, map))

    for (let url of map) {
      let key = url[0]
      let urlName = key.slice(1, key.length)
      let value = url[1]
      let newRegex = new RegExp(value, 'g')
      text = text.replace(newRegex, '<a target="_blank" href="https://t.me/' + urlName + '">' + key + '</a>')
    }

    return text
  }

  checkGuidInText(match: string, map: Map<string, string>): string {
    let guid = Guid.create().toString()

    if (map.has(match)) {
      return map.get(match)!
    } else {
      map.set(match, guid)
      return guid
    }
  }

  replaceText(text: string): string {
    const regex = /(?<!href\s*=\s*["']?)https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi
    let urlMap = new Map<string, string>()
    text = text.replace('\n', '<br>')
    text = text.replace(regex, match => this.checkGuidInText(match, urlMap))
    text = this.checkTelegramLinks(text)
    text = this.replaceTelegramUsers(text)
    for (let url of urlMap) {
      let key = url[0]
      let clearKey = key.replace(/<br>/g, '')
      let value = url[1]
      let newRegex = new RegExp(value, 'g')
      text = text.replace(newRegex, '<a target="_blank" href="' + clearKey + '">' + key + '</a>')
    }
    return text
  }

  async fetchConfig(): Promise<void> {
    this.isLoading = true

    try {
      let data = await ArticlesApi.getArticleRequest('tg', { id: this.$route.params.articleKey })

      this.content = data

      if (data.type === 'data') {
        this.editor = new EditorJS({
          holder: 'editorjs',
          tools: {
            header: Header,
            table: Table,
            simpleImage: SimpleImage,
            warning: Warning,
            quote: Quote,
            marker: Marker,
            list: List,
            embed: Embed as any,
            checkList: CheckList,
            inlineCode: InlineConde,
            underLine: UnderLine,
            delimiter: Delimiter,
            textVariant: TextVariantTune,
            nestedList: NestedList,
            link: {
              class: Link,
              config: {
                endpoint: PostApi.getRequestUrl('tg', 'geturlinfo')
              }
            },
          },
          readOnly: true,
          tunes: [ 'textVariant' ]
        })

        this.editor.isReady.then(() => {
          this.editor!.render(data.content as ArticleRequestData['content'])
        })
      } else if (data.type == 'html') {
        data.content = data.content.replace(/\n/g, ' <br> ')
        data.content = this.replaceText(data.content)

        this.$nextTick(() => {
          if (this.container) {
            this.container.getElementsByClassName('html-text')[0].innerHTML = data.content as HTMLRequestData['content']
          }
        })
      }
    } catch (error: any) {
      errorNotification(error)
    } finally {
      this.isLoading = false
    }
  }

  mounted(): void {
    this.fetchConfig()
  }
}
